<template>
  <div>
    <router-view :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneNumberLink: null,
      phoneNumberFormatted: null,
    };
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    switch (params?.utm_source) {
          case 'facebook':
            this.phoneNumberLink = `tel:+18666280323`
            this.phoneNumberFormatted = '+1 (866) 628-0323'
            break
          case 'everflow':
            this.phoneNumberLink = `tel:+18335331622`
            this.phoneNumberFormatted = '+1 (833) 533-1622'
            break
          case 'outbrain':
            this.phoneNumberLink = `tel:+18774061810` 
            this.phoneNumberFormatted = '+1 (877) 406-1810'
            break
          default:
            this.phoneNumberLink = `tel:+18666570239`
            this.phoneNumberFormatted = '+1 (866) 657-0239'
            break
    }
  },
};
</script>