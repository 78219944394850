<template>
  <section class="md:py-[50px] xl:pt-[120px] pb-[50px] xl:pb-[100px]">
    <div class="max-w-6xl mx-auto px-10">
      <h3
        class="
          text-xl
          md:text-[26px]
          xl:text-[32px]
          text-center text-deepBlue-500
          font-ubuntu font-bold
          mb-[25px]
          md:mb-[50px]
        "
      >
        How we can help.
      </h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-y-[25px]">
        <div class="flex flex-col">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/chat.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Claim Your Benefits</p
          >
        </div>
        <div class="flex flex-col">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/records.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Get Healthcare Coverage</p
          >
        </div>
        <div class="flex flex-col">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/certificate.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Reduce Your Costs</p
          >
        </div>
      </div>
    </div>
  </section>
</template>