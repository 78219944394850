<template>
  <nav class="relative z-10 shadow-lg md:py-4">
    <div
      class="py-3 text-sm font-bold text-center text-white md:hidden bg-deepBlue-500 font-cabin"
    >
      <a class="flex flex-col" :href="phoneNumberLink"
        ><span class="text-xs font-normal"
          >Get a qualified health plan today!</span
        >{{ phoneNumberFormatted }}</a
      >
    </div>
    <div
      class="flex flex-row justify-center max-w-6xl px-10 py-6 mx-auto md:justify-between md:py-0"
    >
      <router-link to="/">
        <LogoColor />
      </router-link>
      <PrimaryLink
        class="flex-row items-center hidden text-sm tracking-widest md:flex"
        :href="phoneNumberLink"
        @click="fireLeadEvent"
      >
        <PhoneIcon class="mr-2.5" />
        {{ phoneNumberFormatted }}
      </PrimaryLink>
    </div>
  </nav>
</template>

<script>
import LogoColor from "@/components/LogoColor.vue";
import PrimaryLink from "@/components/PrimaryLink.vue";
import PhoneIcon from "./PhoneIcon.vue";

export default {
  components: {
    LogoColor,
    PrimaryLink,
    PhoneIcon
  },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      linkNumber: `tel:+18666570239`,
      phoneNumber: '+1 (866) 657-0239',
    };
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    switch (params?.utm_source) {
          case 'facebook':
            this.linkNumber = `tel:+18666280323`
            this.phoneNumber = '+1 (866) 628-0323'
            break
          case 'everflow':
            this.linkNumber = `tel:+18335331622`
            this.phoneNumber = '+1 (833) 533-1622'
            break
    }
  },
  methods: {
    fireLeadEvent: function () {
      if (window.fbq) window.fbq('track', 'Lead')
      if (window.gtag) window.gtag('event', 'generate_lead')
      if (window.obApi) window.obApi('track', 'generate_lead')
      if (window.uetq) window.uetq.push('event', 'generate_lead')
    }
  },
};
</script>