<template>
  <section class="relative flex flex-row justify-between">
    <div class="relative w-1/2 h-[275px] md:h-auto md:py-[78px] xl:py-[195px]">
      <div class="hidden md:block max-w-lg md:ml-10 xl:ml-auto mr-5">
        <h1
          class="
            text-deepBlue-500
            md:text-[32px]
            xl:text-[56px]
            leading-tight
            font-bold font-ubuntu
            pr-5
            xl:pr-2
          "
        >
          Looking for FREE Health Insurance?
        </h1>
        <p
          class="
            text-body text-lg
            tracking-wide
            font-ubuntu font-medium
            md:pr-0
            xl:pr-12
            my-5
          "
        >
          Congress approves
          <span class="text-customLime-600">$0/month</span> health insurance
          with health & vision benefits for qualifying Americans!
        </p>
        <p
          class="
            text-body text-lg
            tracking-wider
            font-ubuntu font-medium
            md:pr-0
            xl:pr-12
          "
        >
          Don't wait - get a qualified health plan today!
        </p>
      </div>
    </div>
    <!-- <div class="relative w-[735px]">
      <img src="../assets/hero-right.png" alt="" class="absolute right-0" />
    </div> -->
  </section>
</template>