<template>
  <section class="relative pt-[338px] pb-[50px] md:py-[156px] xl:py-[299px]">
    <img
      class="absolute top-0 right-0 md:hidden -z-10"
      src="../assets/call-to-action-mobile.png"
      alt=""
    />
    <img
      class="hidden md:block xl:hidden absolute right-0 top-[62px] -z-10"
      src="../assets/call-to-action-tablet.png"
      alt=""
    />
    <img
      class="hidden xl:block absolute right-0 inset-y-[100px] -z-10"
      src="../assets/call-to-action-desktop.png"
      alt=""
    />
    <div class="flex flex-row max-w-6xl px-6 mx-auto md:px-10">
      <div class="flex flex-col md:w-1/2">
        <h3
          class="
            text-[26px]
            xl:text-[32px]
            text-deepBlue-500
            font-ubuntu font-bold
            mb-5
          "
        >
          We're here for you.
        </h3>
        <p class="pr-12 mb-6 text-body font-cabin xl:mb-10 xl:pr-5">
          Don't wait, get a qualified health plan today! Complete your
          application over the phone or simply get your questions answered.
        </p>
        <PrimaryLink
          class="flex flex-row justify-center items-center text-sm md:w-[191px]"
          :href="phoneNumberLink"
          @click="fireLeadEvent"
        >
          <PhoneIcon class="mr-2.5" />
          {{ phoneNumberFormatted }}
        </PrimaryLink>
      </div>
    </div>
  </section>
</template>

<script>
import PrimaryLink from "./PrimaryLink.vue";
import PhoneIcon from "./PhoneIcon.vue";

export default {
  components: {
    PrimaryLink,
    PhoneIcon
  },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  },
  methods: {
    fireLeadEvent: function () {
      if (window.fbq) window.fbq('track', 'Lead')
      if (window.gtag) window.gtag('event', 'generate_lead')
      if (window.obApi) window.obApi('track', 'generate_lead')
      if (window.uetq) window.uetq.push('event', 'generate_lead')
    }
  },
};
</script>