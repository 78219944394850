<template>
  <div
    class="
      bg-white
      drop-shadow-xl
      rounded-[35px]
      mt-12
      py-[50px]
      md:py-[60px]
      xl:pt-[66px] xl:pb-[83px]
      px-[19px]
      md:px-[107px]
      xl:px-[125px]
      max-w-[792px]
      mx-auto
    "
  >
    <h4
      class="
        text-lg
        md:text-[26px]
        text-deepBlue-500 text-center
        font-bold
        px-4
        md:px-0
        mb-2.5
        md:mb-5
        xl:mb-[30px]
      "
    >
      You prequalify for $0 or low-cost health coverage plus vision
      prescriptions <br />
      and more.
    </h4>
    <p class="mt-0.5 mb-5 md:mb-9 xl:mb-5 text-body text-center font-cabin">
      Want to learn more? Explore your options with a healthcare professional.
    </p>
    <img
      class="w-10 h-10 mx-auto mb-5 rotate-90"
      src="../../assets/arrow.gif"
      alt=""
    />
    <PrimaryLink
      class="flex justify-center mb-5 text-sm"
      :href="phoneNumberLink"
      @click="fireLeadEvent"
    >
      <PhoneIcon class="mr-2.5" />
      {{ phoneNumberFormatted }}
    </PrimaryLink>
    <p class="mt-0.5 mb-5 md:mb-9 xl:mb-3 text-body text-center font-cabin">
      Click below for more Health Insurance Options.
    </p>
    <SecondaryLink
      class="flex flex-row items-center justify-center text-sm tracking-wider"
      :href="clickTrackerUrl"
      @click="fireLeadEvent"
    >
      More Options
    </SecondaryLink>
  </div>
</template>

<script>
import PrimaryLink from "../PrimaryLink.vue"
import PhoneIcon from "../PhoneIcon.vue";
import SecondaryLink from "../SecondaryLink.vue"

export default {
  components: { PrimaryLink, SecondaryLink, PhoneIcon },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subId1: null,
      subId2: null,
    };
  },
  computed: {
    clickTrackerUrl: function () {
      let url = 'https://click.clktraker.com/aff_ad?campaign_id=8083&aff_id=15066&hostNameId=2951?subid=bestaffordablecare&clickid={aff_click_id}'

      if (this.subId1) url += `&src=${this.subId1}`
      if (this.subId2) url += `&src2=${this.subId2}`

      return url
    }
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    this.subId1 = params?.utm_source
    this.subId2 = params?.utm_campaign
  },
  methods: {
    fireLeadEvent: function () {
      if (window.fbq) window.fbq('track', 'Lead')
      if (window.gtag) window.gtag('event', 'generate_lead')
      if (window.obApi) window.obApi('track', 'generate_lead')
      if (window.uetq) window.uetq.push('event', 'generate_lead')
    }
  },
};
</script>