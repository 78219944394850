<template>
  <section class="pb-[50px] md:py-[50px] xl:pt-20 xl:pb-24">
    <div class="px-6 xl:max-w-5xl md:px-10 xl:px-12 xl:mx-auto">
      <h3
        class="
          text-xl
          md:text-[26px]
          xl:text-[32px]
          leading-tight
          text-deepBlue-500 text-center
          font-bold font-ubuntu
          xl:mb-0.5
          md:max-w-2xl
          xl:max-w-none
          md:px-12
          xl:px-10
          mx-auto
        "
      >
        Eligible Americans are qualifying for $0 a month health insurance with
        full coverage.
      </h3>
      <p
        class="
          text-body
          xl:text-lg
          text-center
          font-ubuntu font-medium
          mt-2.5
          md:mt-5
          xl:mt-7
          mb-0.5
        "
      >
        Take this short quiz to see if you qualify!
      </p>
      <Quiz1 v-show="step === 1" @yes="under65_yes" @no="under65_no" />
      <Quiz2
        v-show="step === 2"
        @yes="live_in_the_us_yes"
        @no="live_in_the_us_no"
      />
      <Quiz3
        v-show="step === 3"
        @yes="income_under_50k_yes"
        @no="income_under_50k_no"
      />
      <Quiz4
        v-show="step === 4"
        @yes="free_insurance_yes"
        @no="free_insurance_no"
      />
      <Quiz5 v-show="step === 5" />
      <Quiz6 v-show="step === 6" :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
    </div>
  </section>
</template>

<script>
import Quiz1 from "./Quiz/Quiz1.vue";
import Quiz2 from "./Quiz/Quiz2.vue";
import Quiz3 from "./Quiz/Quiz3.vue";
import Quiz4 from "./Quiz/Quiz4.vue";
import Quiz5 from "./Quiz/Quiz5.vue";
import Quiz6 from "./Quiz/Quiz6.vue";

export default {
  name: "Question",
  components: {
    Quiz1,
    Quiz2,
    Quiz3,
    Quiz4,
    Quiz5,
    Quiz6,
  },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      under65: null,
      live_in_the_us: null,
      income_under_50k: null,
      free_insurance: null,
    };
  },
  methods: {
    under65_yes: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionOne', {}, { event_id: 'question_1_yes' })
      if (window.gtag) window.gtag('event', 'question_one', { 'answer': 'yes' })
      if (window.obApi) window.obApi('track', 'QuestionOne')
      if (window.uetq) window.uetq.push('event', 'question_one')
      this.step = 2;
      this.under65 = true;
    },
    under65_no: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionOne', {}, { event_id: 'question_1_no' })
      if (window.gtag) window.gtag('event', 'question_one', { 'answer': 'no' })
      if (window.obApi) window.obApi('track', 'QuestionOne')
      if (window.uetq) window.uetq.push('event', 'question_one')
      this.step = 2;
      this.under65 = false;
    },
    live_in_the_us_yes: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionTwo', {}, { event_id: 'question_2_yes' })
      if (window.gtag) window.gtag('event', 'question_two', { 'answer': 'yes' })
      if (window.obApi) window.obApi('track', 'QuestionTwo')
      if (window.uetq) window.uetq.push('event', 'question_two')
      this.step = 3;
      this.live_in_the_us = true;
    },
    live_in_the_us_no: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionTwo', {}, { event_id: 'question_2_no' })
      if (window.gtag) window.gtag('event', 'question_two', { 'answer': 'no' })
      if (window.obApi) window.obApi('track', 'QuestionTwo')
      if (window.uetq) window.uetq.push('event', 'question_two')
      this.step = 3;
      this.live_in_the_us = false;
    },
    income_under_50k_yes: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionThree', {}, { event_id: 'question_3_yes' })
      if (window.gtag) window.gtag('event', 'question_three', { 'answer': 'yes' })
      if (window.obApi) window.obApi('track', 'QuestionThree')
      if (window.uetq) window.uetq.push('event', 'question_three')
      this.step = 4;
      this.income_under_50k = true;
    },
    income_under_50k_no: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionThree', {}, { event_id: 'question_3_no' })
      if (window.gtag) window.gtag('event', 'question_three', { 'answer': 'no' })
      if (window.obApi) window.obApi('track', 'QuestionThree')
      if (window.uetq) window.uetq.push('event', 'question_three')
      this.step = 4;
      this.income_under_50k = false;
    },
    free_insurance_yes: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionFour', {}, { event_id: 'question_4_yes' })
      if (window.gtag) window.gtag('event', 'question_four', { 'answer': 'yes' })
      if (window.obApi) window.obApi('track', 'QuestionFour')
      if (window.uetq) window.uetq.push('event', 'question_four')
      this.step = 5;
      this.free_insurance = true;
      setTimeout(() => (this.step = 6), 3000);
    },
    free_insurance_no: function () {
      if (window.fbq) window.fbq('trackCustom', 'QuestionFour', {}, { event_id: 'question_4_no' })
      if (window.gtag) window.gtag('event', 'question_four', { 'answer': 'no' })
      if (window.obApi) window.obApi('track', 'QuestionFour')
      if (window.uetq) window.uetq.push('event', 'question_four')
      this.step = 5;
      this.free_insurance = false;
      setTimeout(() => (this.step = 6), 3000);
    },
  },
};
</script>