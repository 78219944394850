<template>
  <section class="relative pb-[50px] md:pt-[90px] md:pb-[113px] xl:py-[265px]">
    <img class="md:hidden" src="../assets/about-us-mobile.png" alt="" />
    <img
      class="hidden md:block xl:hidden absolute top-[50px] -z-10"
      src="../assets/about-us-tablet.png"
      alt=""
    />
    <img
      class="hidden xl:block absolute inset-y-[100px] -z-10"
      src="../assets/about-us-desktop.png"
      alt=""
    />
    <div
      class="flex flex-row max-w-6xl px-6 pt-6 mx-auto md:justify-end md:py-0 xl:px-10"
    >
      <div class="flex flex-col md:pl-8 xl:pl-0 md:w-1/2">
        <h3
          class="
            text-[26px]
            xl:text-[32px]
            text-deepBlue-500
            font-ubuntu font-bold
            mb-5
          "
        >
          About us.
        </h3>
        <p class="pr-8 mb-6 text-body font-cabin xl:mb-10">
          Finding health insurance that fits all your medical needs doesn’t need
          to be difficult. Our mission is to help every American that qualifies
          for health coverage to claim their benefits at the lowest cost
          possible. Our helpline connects you to agents that can assist you with
          getting you the lowest cost health coverage plan available to you.
        </p>
        <PrimaryLink
          class="flex flex-row justify-center items-center text-sm md:w-[191px]"
          :href="phoneNumberLink"
          @click="fireLeadEvent"
        >
          <PhoneIcon class="mr-2.5" />
          {{ phoneNumberFormatted }}
        </PrimaryLink>
      </div>
    </div>
  </section>
</template>

<script>
import PrimaryLink from "./PrimaryLink.vue";
import PhoneIcon from "./PhoneIcon.vue";

export default {
  components: {
    PrimaryLink,
    PhoneIcon
  },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  },
  methods: {
    fireLeadEvent: function () {
      if (window.fbq) window.fbq('track', 'Lead')
      if (window.gtag) window.gtag('event', 'generate_lead')
      if (window.obApi) window.obApi('track', 'generate_lead')
      if (window.uetq) window.uetq.push('event', 'generate_lead')
    }
  },
};
</script>