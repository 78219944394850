<template>
  <section
    class="
      bg-bgGray
      py-[55px]
      md:py-[50px]
      xl:py-[100px]
      px-[38px]
      md:px-[92px]
      text-deepBlue-500
    "
  >
    <div class="max-w-6xl mx-auto xl:px-10">
      <div class="mb-6 md:mb-7">
        <Submark class="mx-auto xl:hidden" />
        <LogoColor class="hidden xl:block" />
      </div>
      <div
        class="mt-0.5 flex flex-col xl:flex-row items-center xl:justify-between"
      >
        <div class="flex flex-col order-2 text-center xl:order-1 xl:text-left">
          <p class="mb-2 text-xs font-cabin">Call now!</p>
          <a
            class="mb-2 text-sm font-bold font-cabin"
            :href="phoneNumberLink"
            @click="fireLeadEvent"
            >{{ phoneNumberFormatted }}</a
          >
          <p class="mb-5 text-xs font-cabin">
            Hours of operation: 8am to 8pm ET
          </p>
          <p class="mb-5 text-xs font-cabin">
            &copy; 2022 Best Affordable Care. All rights reserved.
          </p>
        </div>
        <div
          class="
            flex flex-col
            xl:flex-row
            items-center
            space-y-6
            md:space-y-[30px]
            xl:space-y-0
            order-1
            xl:order-2
            mb-6
            md:mb-[30px]
            xl:mb-0
          "
        >
          <a
            class="text-sm font-bold font-cabin xl:mr-9"
            href="https://www.reservetechinc.com/privacy/"
            >Privacy Policy</a
          >
          <a
            class="text-sm font-bold font-cabin"
            href="https://www.reservetechinc.com/termsandconditions.php"
            >Terms & Conditions</a
          >
        </div>
      </div>
      <div>
        <p
          class="
            text-[10px]
            xl:text-xs
            text-center
            xl:text-left
            font-cabin
            px-[7px]
            md:px-3.5
            xl:px-0
            mb-5
            xl:mr-2
          "
        >
          To request plan information without providing personal information,
          please call the phone number above. We are not affiliated with any
          plan or endorsed by any government entity or agency. We connect
          individuals with insurance providers and other affiliates
          (collectively, “partners”) to give you, the consumer, an opportunity
          to get information about insurance and connect with licensed insurance
          agents. By completing the quotes form or calling the number listed
          above, you will be directed to a partner that can connect you to an
          appropriately licensed insurance agent who can answer your questions
          and discuss plan options.We do not offer every plan available in your
          area. Any information we provide is limited to those plans we do offer
          in your area. Please contact Medicare.gov or 1-800-MEDICARE to get
          information on all of your options. Products and services are provided
          exclusively by our partners, but not all offer the same plans or
          options. Possible options that may be offered include, but are not
          limited to, ACA-Qualified Plans, Medicare Advantage Plans, Short Term
          Plans, Christian/Health Sharing Plans, and Fixed Indemnity Plans.
          Descriptions are for informational purposes only and subject to
          change. We encourage you to shop around and explore all of your
          options. We are not affiliated with or endorsed by any government
          entity or agency. By using this site, you acknowledge that you have
          read and agree to the Privacy Policy and Terms & Conditions.
        </p>
        <p
          class="
            text-[10px]
            xl:text-xs
            text-center
            xl:text-left
            font-cabin
            px-1.5
            md:px-4
            xl:px-0
            mb-5
            xl:mr-2
          "
        >
          The Medicare plans represented are PDP, HMO, PPO or PFFS plans with a
          Medicare contract. Enrollment in plans depends on contract renewal.
          Enrollment in a plan may be limited to certain times of the year
          unless you qualify for a special election period, or you are in your
          Medicare Initial Election Period. Not all plans offer all of the
          benefits mentioned. $0 premium plans are not available in all areas.
          Enrollees must continue to pay their Medicare Part B Premium.
          Deductibles, copay and coinsurance may apply.
        </p>
        <p
          class="
            text-[10px]
            xl:text-xs
            text-center
            xl:text-left
            font-cabin
            px-1.5
            md:px-3.5
            xl:px-0 xl:mb-2
          "
        >
          Plans are insured or covered by a Medicare Advantage organization with
          a Medicare contract and/or a Medicare-approved Part D sponsor.
          Enrollment in the plan depends on the plan’s contract renewal with
          Medicare.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import LogoColor from "./LogoColor.vue";
import Submark from "./Submark.vue";

export default {
  components: {
    LogoColor,
    Submark,
  },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      linkNumber: `tel:+18666570239`,
      phoneNumber: '+1 (866) 657-0239',
    };
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    switch (params?.utm_source) {
          case 'facebook':
            this.linkNumber = `tel:+18666280323`
            this.phoneNumber = '+1 (866) 628-0323'
            break
          case 'everflow':
            this.linkNumber = `tel:+18335331622`
            this.phoneNumber = '+1 (833) 533-1622'
            break
    }
  },
  methods: {
    fireLeadEvent: function () {
      if (window.fbq) window.fbq('track', 'Lead')
      if (window.gtag) window.gtag('event', 'generate_lead')
      if (window.obApi) window.obApi('track', 'generate_lead')
      if (window.uetq) window.uetq.push('event', 'generate_lead')
    }
  },
};
</script>